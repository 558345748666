import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useGlobals from 'redux/globals';
import { selectGlobals } from 'redux/globals/selectors';
import { authPaquerypointSelector } from 'redux/auth/selectors';
import { logout } from 'redux/logout';
import { NavigationLayout } from '@paquery-team/lib-app-layout';
import {
  ProfileOutlined,
  ImportOutlined,
  ContainerOutlined,
  SearchOutlined,
  ExportOutlined,
  UserOutlined,
  GiftOutlined,
  NodeIndexOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Switch, Route, Redirect } from 'react-router-dom';
import SITELINKS from 'constants/sitelinks';
import Logo from 'constants/paquery-logo.png';
import ArrivePacket from 'routes/app/arrivePacket';
import DeliverPickup from 'routes/app/deliverPickup';
import DeparturePacket from 'routes/app/departurePacket';
import PackagesList from 'routes/app/packageList';
import PackagesHistory from 'routes/app/packageHistory';
import PackageView from 'routes/app/packageView';
import PackagesWithPaquersHistorical from 'routes/app/packageWithPaquersHistorical';
import PackageModify from 'routes/app/packageModify';
import { Spin, ConfigProvider } from 'antd';
import useZones from 'redux/zones';
import useRoutesTracking from 'redux/routesTracking';
import useRoutesHistory from 'redux/routesHistory';
import { useAlert } from 'services/AlertService';
import { useQueryClient } from '@tanstack/react-query';
import PackagesWithPaquers from './packagesWithPaquers';
import PackagesWithoutZone from './packagesWithoutZone';
import UserProfile from './userProfile';
import PackageSearchByCode from './packageSearchByCode';
import DeparturePaqueryPointPacket from './departurePaqueryPoint';
import DeparturePaqueryPointHistory from './departurePaqueryPointHistory';
import RoutesTracking from './routesTracking';
import RouteDetail from './route';
import RoutesHistory from './routesHistory';
import PaquerList from './paquerList';
import Paquer from './paquerModify';
import RouteGenerate from './routeGenerate';
import Support from './support';

const menuItems = [
  {
    key: 'packagesBySearchCode',
    title: 'Buscar por código',
    icon: <SearchOutlined />,
    link: SITELINKS.packages.bySearchCode,
  },
  {
    key: 'arrivePacket',
    title: 'Ingresar',
    icon: <ImportOutlined />,
    link: SITELINKS.packet.entry,
  },
  {
    key: 'deliverPickup',
    title: 'Entrega Pickup',
    icon: <GiftOutlined />,
    link: SITELINKS.packet.deliver,
  },
  {
    key: 'router',
    title: 'Rutas',
    icon: <NodeIndexOutlined />,
    subMenuItems: [
      // {
      //   key: 'routeGenerate',
      //   title: 'Generar in progress',
      //   link: SITELINKS.routes.create,
      // },
      {
        key: 'paquerDeparturePacket',
        title: 'Generar',
        link: SITELINKS.packet.departure.paquer,
      },
      {
        key: 'routesTracking',
        title: 'Seguimiento',
        link: SITELINKS.routes.list,
      },
      {
        key: 'routesHistory',
        title: 'Histórico',
        link: SITELINKS.routes.history,
      },
    ],
  },
  {
    key: 'departurePacket',
    title: 'Transferir/Devolver',
    icon: <ExportOutlined />,
    subMenuItems: [
      {
        key: 'crossdockingDeparturePacket',
        title: 'Transferir',
        link: SITELINKS.packet.departure.paqueryPoint,
      },
      {
        key: 'historicDeparture',
        title: 'Histórico',
        link: SITELINKS.packet.departure.history,
      },
    ],
  },
  {
    key: 'packages',
    title: 'Paquetes',
    icon: <ContainerOutlined />,
    subMenuItems: [
      {
        key: 'packageList',
        title: 'En transito',
        link: SITELINKS.packages.list,
      },
      {
        key: 'packageHistory',
        title: 'Histórico',
        link: SITELINKS.packages.history,
      },
      {
        key: 'packagePaquer',
        title: 'Paquetes por paquer',
        link: SITELINKS.packages.withPaquers,
      },
      {
        key: 'packagePaquerHistory',
        title: 'Histórico por paquer',
        link: SITELINKS.packages.withPaquersHistorical,
      },
      {
        key: 'packageWithoutZone',
        title: 'Sin zona',
        link: SITELINKS.packages.withoutZone,
      },
    ],
  },
  {
    key: 'paquers',
    title: 'Paquers',
    icon: <UserOutlined />,
    link: SITELINKS.paquers.list,
  },
  {
    key: 'profile',
    title: 'Perfil',
    icon: <ProfileOutlined />,
    link: SITELINKS.profile,
  },
  {
    key: 'support',
    title: 'Soporte',
    link: SITELINKS.support.index,
    icon: <QuestionCircleOutlined />,
  },
];

const LoadingApplicationData = () => (
  <div
    style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Spin spinning />
  </div>
);

const useIsApplicationReady = () => {
  useGlobals();
  const globals = useSelector(selectGlobals);
  return globals.loaded;
};

const App = () => {
  useZones();
  useRoutesTracking();
  useRoutesHistory();
  const dispatch = useDispatch();
  const isReady = useIsApplicationReady();
  const paquerypoint = useSelector(authPaquerypointSelector);
  const { data: alert } = useAlert();
  const queryClient = useQueryClient();

  if (!isReady) {
    return <LoadingApplicationData />;
  }

  const username = paquerypoint.detail;
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgLayout: '#254370',
          colorPrimary: '#0db8b2',
        },
      }}
    >
      <NavigationLayout
        menuItems={menuItems}
        userName={username}
        logo={Logo}
        alert={{
          message: alert?.message,
          type: alert?.type ?? 'info',
          closable: true,
        }}
        logout={() => {
          queryClient.invalidateQueries(['alert']);
          dispatch(logout());
        }}
        showLangSelector={false}
      >
        <Switch>
          <Route
            path={SITELINKS.packages.bySearchCode}
            render={(props) => <PackageSearchByCode {...props} />}
          />
          <Route
            path={SITELINKS.packet.entry}
            render={(props) => <ArrivePacket {...props} />}
          />
          <Route
            path={SITELINKS.packet.deliver}
            render={(props) => <DeliverPickup {...props} />}
          />
          <Route
            path={SITELINKS.packet.departure.history}
            render={(props) => <DeparturePaqueryPointHistory {...props} />}
          />
          <Route
            path={SITELINKS.packet.departure.paquer}
            render={(props) => <DeparturePacket {...props} />}
          />
          <Route
            exact
            path={SITELINKS.routes.history}
            render={(props) => <RoutesHistory {...props} />}
          />
          <Route
            path={SITELINKS.routes.create}
            render={(props) => <RouteGenerate {...props} />}
          />
          <Route
            path={SITELINKS.routes.view}
            render={(props) => <RouteDetail {...props} />}
          />
          <Route
            path={SITELINKS.routes.list}
            render={(props) => <RoutesTracking {...props} />}
          />
          <Route
            path={SITELINKS.packet.departure.paqueryPoint}
            render={(props) => <DeparturePaqueryPointPacket {...props} />}
          />
          <Route
            path={SITELINKS.packages.history}
            render={(props) => <PackagesHistory {...props} />}
            exact
          />
          <Route
            path={SITELINKS.packages.list}
            render={(props) => <PackagesList {...props} />}
            exact
          />
          <Route
            path={SITELINKS.packages.withoutZone}
            render={(props) => <PackagesWithoutZone {...props} />}
            exact
          />
          <Route
            path={SITELINKS.packages.withPaquers}
            render={(props) => <PackagesWithPaquers {...props} />}
            exact
          />
          <Route
            path={SITELINKS.packages.withPaquersHistorical}
            render={(props) => <PackagesWithPaquersHistorical {...props} />}
            exact
          />
          <Route
            path={SITELINKS.packages.view}
            render={(props) => <PackageView {...props} />}
          />
          <Route
            path={SITELINKS.packages.byId}
            render={(props) => <PackageModify {...props} />}
          />
          <Route
            path={SITELINKS.packages.byExternalCode}
            render={(props) => <PackageModify {...props} />}
            exact
          />
          <Route
            path={SITELINKS.paquers.list}
            render={(props) => <PaquerList {...props} />}
            exact
          />
          <Route
            path={SITELINKS.paquers.view}
            render={(props) => <Paquer {...props} />}
          />
          <Route
            path={SITELINKS.profile}
            render={(props) => <UserProfile {...props} />}
          />
          <Route path={SITELINKS.support.index} render={() => <Support />} />
          <Redirect to={SITELINKS.packet.entry} />
        </Switch>
      </NavigationLayout>
    </ConfigProvider>
  );
};

export default App;
