const baseUrl = `${process.env.REACT_APP_API_URL}/api`;
const msApiUrl = process.env.REACT_APP_MS_API_URL;

export default {
  auth: {
    login: `${process.env.REACT_APP_API_URL}/apptoken`,
    recover: `${baseUrl}/customeradmin/sendrecoverypassword?Email=`,
    confirm: `${baseUrl}/customeradmin/recoverypassword`,
  },
  profile: {
    load: `${baseUrl}/profile/load`,
    paquerypointInfo: (pqPointId) =>
      `${msApiUrl}/pqpoint/paquerypoint/${pqPointId}`,
  },
  globals: {
    packageType: `${msApiUrl}/packages/parametrics/packageType`,
    packageSize: `${msApiUrl}/packages/parametrics/packageSize`,
    packageStatus: `${msApiUrl}/packages/parametrics/packageStatus`,
    deliveryPackageType: `${msApiUrl}/packages/parametrics/deliveryPackageType`,
    deliveryTerm: `${msApiUrl}/packages/parametrics/deliveryTerm`,
    countries: `${baseUrl}/geolocation/loadcountries`,
    cities: `${baseUrl}/geolocation/loadcities`,
    roles: `${baseUrl}/apptype/userrole`,
    logisticOperator: `${msApiUrl}/opl/logisticoperator?page=0&size=1000`,
    userType: `${baseUrl}/apptype/usertype`,
    daysOfWeek: `${msApiUrl}/pqpoint/paquerypoint/daysOfWeek`,
    currentServices: `${msApiUrl}/pqpoint/paquerypoint/currentServices`,
    dispatchTypes: `${msApiUrl}/packages/parametrics/dispatchType`,
  },
  packages: {
    get: `${msApiUrl}/packages/packages`,
    getById: `${msApiUrl}/packages/packages/`,
    getByExternalCode: `${msApiUrl}/packages/packages/findByExternalCode`,
    getByExternalCodeFromArrivedOrDispatch: `${msApiUrl}/packages/packages/findByExternalCodeFromArrivedOrDispatch`,
    findByExternalCodeDispatch: `${msApiUrl}/packages/dispatch/findByExternalCode`,
    history: `${msApiUrl}/packages/packages/history`,
    updateStatus: `${msApiUrl}/packages/packages/searchArrived/`,
    arrivePacket: `${msApiUrl}/packages/packages/arrivals`,
    arrivePacketByID: (id) => `${msApiUrl}/packages/packages/arrive/id/${id}`,
    deliverPickup: `${msApiUrl}/packages/packages/pickup/delivery`,
    deliverPickupByID: (id) => `${msApiUrl}/packages/pickup/arrive/id/${id}`,
    withPaquers: `${msApiUrl}/packages/packages/withPaquers`,
    withoutZone: `${msApiUrl}/packages/packages/withoutzone`,
    withPaquersHistorical: `${msApiUrl}/packages/packages/history`,
    label: `${msApiUrl}/labels/packages/`,
    isPackageBundle: `${msApiUrl}/packages/packages/isPackageBundle`,
    downloadDeliveryNoteById: (id) =>
      `${msApiUrl}/labels/packages/remittance/${id}`,
    getBundles: (id) => `${msApiUrl}/packages/bundles/${id}`,
  },
  departures: {
    create: `${msApiUrl}/packages/dispatch`,
    getAll: `${msApiUrl}/packages/dispatch`,
    getById: (dispatchId) =>
      `${msApiUrl}/packages/dispatch/packages/${dispatchId}`,
    receipt: (dispatchId) =>
      `${msApiUrl}/labels/dispatch/receipt/${dispatchId}`,
    getPackagesNotArrivedByCode: (dispatchCode) =>
      `${msApiUrl}/packages/dispatch/packages/notArrived?qrCode=${dispatchCode}`,
  },
  paquer: {
    get: `${msApiUrl}/packages/packages`,
    getById: `${msApiUrl}/pqr/paquer/`,
    getPackages: `${msApiUrl}/packages/packages/current/byDriver/`,
    assign: `${msApiUrl}/packages/packages/searchRolled/`,
    getAll: `${msApiUrl}/pqr/paquer`,
    unassign: `${msApiUrl}/packages/paquer/unassign/`,
    history: `${msApiUrl}/packages/packages/byDriver/`,
  },
  paqueryPoint: {
    getAll: `${msApiUrl}/pqpoint/paquerypoint/availables`,
  },
  marketplaces: {
    get: `${msApiUrl}/mkp/marketplace`,
    getById: (mkpId) => `${msApiUrl}/mkp/marketplace/${mkpId}`,
  },
  stores: {
    getById: (id) => `${msApiUrl}/mkp/store/${id}`,
    getByMarketplace: `${msApiUrl}/mkp/store/marketplace`,
  },
  reports: {
    history: `${msApiUrl}/reports/report/history`,
    packagesbyPaquer: `${msApiUrl}/reports/report/packagesWithPaquers`,
    packagesOwnedByPaquer: `${msApiUrl}/reports/report/paquer`,
    historyByPaquer: `${msApiUrl}/reports/report/paquer/history`,
    withoutZone: `${msApiUrl}/reports/report/withoutzone`,
    packages: `${msApiUrl}/reports/report`,
  },
  zones: {
    getAllByLogisticOperator: (id) =>
      `${msApiUrl}/zones/logistics_operator/${id}`,
    resolve: `${msApiUrl}/mediator/resolveZone`,
  },
  user: {
    getAll: `${baseUrl}/customeradmin/getall/`,
    update: `${baseUrl}/customeradmin/update`,
  },
  operators: {
    getAll: `${msApiUrl}/opl/integration/opl/availables`,
  },
  alert: `${msApiUrl}/fe/messages`,
  whatsapp: {
    sendMessage: 'https://web.whatsapp.com/send?phone=',
  },
  routes: {
    getAllStarted: `${msApiUrl}/pq-router/route/getroutestarted`,
    getById: (id) => `${msApiUrl}/pq-router/route/getroutedetaill/${id}`,
    getActiveByPaquerId: `${msApiUrl}/pq-router/route/driver/packages/info`,
    history: `${msApiUrl}/pq-router/route/getroutehistory`,
    endRouteById: (id) => `${msApiUrl}/pq-router/route/setstate-end/${id}`,
    recreateRouteById: (id) => `${msApiUrl}/pq-router/route/recalculate/${id}`,
    generateByPaquer: `${msApiUrl}/pq-router/vrp/driver/create-route`,
  },
  adresses: {
    getGeolocalized: `${msApiUrl}/addresses/geolocalize`,
  },
};
